<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">代理商管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">代理商列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div style="display:flex;flex-direction: column;align-items: center;height:100%;justify-content: center;">
              <div style="display:flex;flex-direction: column;align-items: center;">
                <img src="../../assets/Successful.png" alt="" />
                <h3 style="color:#8ABEF6;font-size:26px">入驻成功</h3>
              </div>
              <div style="padding-top:5rem">
                <el-button @click="doComplete"  style="width:12rem">完 成</el-button>
                <el-button class="bgc-bv" @click="createAccount" style="width:12rem">创建账号</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "agentSuccess",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
      //完成
      doComplete() {
        if(this.$route.query.contract) {
          this.$router.push({
              path:'/web/financialSettlement/contractManagementAdd',
          })
        } else {
          this.$router.push({
              path:'/web/agentList',
          })
        }
          
      },
      //创建帐号
      createAccount() {
           let roleId = '-3';
           if ('20' == this.$route.query.agencyCooperationType) {
             roleId = '-5';
           }
           this.$router.push({
              path:'/web/operate/accountEdit',
              query:{
                  stu:'add',
                  agencyId: this.$route.query.agencyId,
                  trainTypeIds:this.$route.query.trainTypeIds,
                  roleId: roleId,
                  agencyCooperationType: this.$route.query.agencyCooperationType
              }
          })
      }
  },
};
</script>
<style lang="less"></style>
<style lang="less" scoped></style>
